import React, { useContext, useEffect, useMemo, useState } from "react";
import "../assets/css/Dashboard/dashboard.css";

import Navbar from "../components/Navbar/Navbar";
import Header from "../components/Header/Header";
import Status from "../components/Status/Status";
import IndoorMapV2 from "../components/IndoorMap/IndoorMapV2";
import RemovedStatus from "../components/RemovedStatus/RemovedStatus";
import ActiveShipment from "../components/ActiveShipment/ActiveShipment";
import TimeMachine from "../components/TimeMachine/TimeMachine";
import YardView from "../components/YardView/YardView";

import {
  isTokenExpired,
  authExpirationNavigator,
  getScreenSize,
  setLocalStorageItem,
  apiHeadersFormatter,
  getLocalStorageItem,
  filterActivePlant,
  expireAuthToken,
  getAvailablePlant,
  clearLocalStorageItem,
  getPlantStates,
  isScreenBigger,
  reloadApp,
  getPlantConfig,
} from "../util/helper/helperFunctions";
import WarehouseModal from "../components/Modal/WarehouseModal/WarehouseModal";
import { AppContext } from "../Context/Context";
import CustomizePopup from "../components/Modal/CustomizePopup/CustomizePopup";
import LayoutPopup from "../components/Modal/LayoutModal/LayoutPopup";
import AdditionalDashboardModal from "../components/Modal/AdditionalDashboard/AdditionalDashboardModal";
import {
  plantConfigUrl,
  plantDetailsAPI,
  plantLocations,
} from "../util/network/apiUrl";
import LegendsPopup from "../components/Modal/LegendPopup/LegendPopup";
import AllocatedView from "../components/Modal/AllocatedView/AllocatedView";
import LgvPopup from "../components/Modal/LgvPopup/LgvPopup";
import DockDoorPopup from "../components/Modal/DockDoorPopup/DockDoorPopup";
import LineProductionShowModal from "../components/Modal/LineProductionShowModal/LineProductionShowModal";
import MapModal from "../components/Modal/MapModal/MapModal";
import Greetings from "../components/Greetings/Greetings";
import TimeMachineNoDataModal from "../components/Modal/TimeMachineNoData/TimeMachineNoData";
import { initiateSocketConnection } from "../socket/socket";
import Heatmap from "../components/HeatMap/Heatmap";
import BlockRackModal from "../components/Modal/BlockRackModal/BlockRackModal";
import NonLgvModal from "../components/Modal/NonLgvModal/NonLgvModal";
import Alert from "../components/Reusables/AlertMessage/AlertMessage";
import AlertMessage from "../components/Reusables/AlertMessage/AlertMessage";
import DraggableComponent from "../components/DragableComponent/DraggableComponent";
import { name } from "@azure/msal-browser/dist/packageMetadata";
import BatteryStationObjectPopup from "../components/Modal/BatteryStationObjectPopup/BatteryStationObjectPopup";
import { getApi, postApi } from "../util/network/getApi";
const Dashboard = () => {
  //Removed Status
  const [fleetData, setFleetData] = useState({
    fleetDataCount: [],
    lgvStatus: [],
  });
  const [fleetStatus, setFleetStatus] = useState();
  const [fleetCount, setFleetCount] = useState();
  const { removedLgvViewIsOpen, setRemovedLgvViewIsOpen } =
    useContext(AppContext);

  //Shipment Status
  const [shipmentData, setShipmentData] = useState();
  const [activeShipmentData, setActiveShipmentData] = useState();
  const {
    locationsData,
    setLocationsData,
    activeLGVModule,
    setActiveLGVModule,
  } = useContext(AppContext);

  //Shift Performance
  const [shiftPerformanceData, setShiftPerformanceData] = useState();

  //Warehouse
  const [warehouseData, setWarehouseData] = useState();
  const { warehouseModelIsOpen, setWarehouseModelIsOpen } =
    useContext(AppContext);
  const { warehouseModel, setWarehouseModel, plantConfig, setPlantConfig } =
    useContext(AppContext);

  //Line Production
  const { lineProductionData, setLineProductionData } = useContext(AppContext);
  const { productionLineViewIsOpen, setProductionLineViewIsOpen } =
    useContext(AppContext);

  //Dock door
  const [dockDoorData, setDockDoorData] = useState();
  const { dockDoorViewIsOpen, setDockDoorViewIsOpen } = useContext(AppContext);

  //Additional Dashboard
  const { additionalDashboardModalIsOpen, setAdditionalDashboardModalIsOpen } =
    useContext(AppContext);

  const [errorApi, setErrorApi] = useState(0);
  const { selectedMarkerData, setSelectedMarkerData } = useContext(AppContext);
  const { selectedMarkerType, setSelectedMarkerType } = useContext(AppContext);

  //Popup
  const { isLegendsPopupOpen, setIsLegendsPopupOpen } = useContext(AppContext);
  const { customizePopup, setCustomizePopup, reRenderKey } =
    useContext(AppContext);
  const { layoutPopup, setLayoutPopup } = useContext(AppContext);
  const { isMarkerPopupOpen, setIsMarkerPopupOpen } = useContext(AppContext);
  const [location, setLocation] = useState("");
  const { locationModal, setLocationModal } = useContext(AppContext);
  const { locationCode, setLocationCode } = useContext(AppContext);

  // Allocated View Data
  const [allocatedViewData, setAllocatedViewData] = useState([]);
  // Allocated View Popup State
  const { allocatedView, setAllocatedView } = useContext(AppContext);

  //Heatmap
  const { isNoHeatMapDataModalOpen, setIsNoHeatMapDataModalOpen } =
    useContext(AppContext);
  const { isHeatmapModeEnabled, setIsHeatmapModeEnabled, setFeatureList } =
    useContext(AppContext);

  //TimeMachine
  const { timeMachineModeIsEnabled, setTimeMachineModeIsEnabled } =
    useContext(AppContext);

  //Socket Connection
  const [socketConnection, setSocketConnection] = useState();
  const { connectionStatus, setConnectionStatus } = useContext(AppContext);

  //Block Rack Storage
  const { isBlockRackModalOpen, setIsBlockRackModalOpen } =
    useContext(AppContext);
  const { selectedBlockRackLocationData, setSelectedBlockRackLocationData } =
    useContext(AppContext);

  //Non LGV Storage
  const { isNonLgvModalOpen, setIsNonLgvModalOpen } = useContext(AppContext);
  const { nonLgvData, setNonLgvData } = useContext(AppContext);

  //Alert
  const { alertObject, setAlertObject } = useContext(AppContext);

  let currentSubPath = window.location.pathname.split("/")[2];

  useEffect(() => {
    const pathName = window.location.pathname;
    let existingLocation = getLocalStorageItem("locationCode");
    let pathCode = pathName.split("/")[1];
    if (existingLocation && pathCode) {
      if (existingLocation !== pathCode) {
        window.location.replace(`${window.location.origin}`);
      }
    }
  }, [getLocalStorageItem("locationCode")]);

  const closeLocationModal = () => {
    setLocationModal(false);
  };

  const openLocationModel = () => {
    setLocationModal(true);
  };

  const closeHeatmapModal = () => {
    setIsHeatmapModeEnabled(false);
  };

  const openHeatmapModel = () => {
    setIsHeatmapModeEnabled(true);
  };

  const setLocationToURL = () => {
    let appUrl = new URL(window.location.href);
    appUrl.searchParams.append("location", getLocalStorageItem("locationCode"));
  };

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName.length !== 1) {
      let locationCode = pathName.split("/")[1];
      if (locationCode && getAvailablePlant(locationCode)) {
        let stateName = getPlantStates(locationCode);
        setLocation(stateName);
        setLocalStorageItem("locationSelected", stateName);
        setLocalStorageItem("locationCode", locationCode);
        setLocationCode(locationCode);
      } else {
        let existingLocation = getLocalStorageItem("locationCode");
        if (existingLocation) {
          window.location.replace(
            `${window.location.origin}/${existingLocation}`
          );
        } else {
          window.location.replace(window.location.origin);
        }
      }
    } else {
      let locationCode = getLocalStorageItem("locationCode");
      if (locationCode && locationCode.length !== 0) {
        window.location.replace(`${window.location.origin}/${locationCode}`);
      }
    }
    setTimeMachineModeIsEnabled(false);
    localStorage.setItem("isTimeMachineModeEnabled", "false");
    setLocalStorageItem("seekPosition", 1);
    setLocationToURL();
    setLocalStorageItem("enableLgvMarkers", true);
  }, []);

  //Socket connection
  useEffect(() => {
    if (getLocalStorageItem("locationCode")) {
      try {
        let locationCode = getLocalStorageItem("locationCode");
        let socketInstance = initiateSocketConnection(locationCode);
        setSocketConnection(socketInstance);
        setConnectionStatus(true);

        socketInstance.on("reconnect", () => {
          setConnectionStatus(true);
        });

        socketInstance.on("disconnect", (reason) => {
          setConnectionStatus(false);
          if (reason === "io server disconnect") {
            socketInstance.connect();
            setConnectionStatus(true);
          }
        });

        return () => {
          socketInstance.disconnect();
          setConnectionStatus(false);
        };
      } catch (err) {
        console.log(err);
      }
    }
  }, [getLocalStorageItem("locationCode")]);

  useEffect(() => {
    let selectedLocation = localStorage.getItem("locationSelected");
    if (selectedLocation !== "") {
      setLocation(selectedLocation);
    }
  }, [localStorage.getItem("locationSelected")]);

  useEffect(() => {
    //Filter Active Plants and Update global context
    const filterActivePlantsAndUpdateContext = (plantData) => {
      let filteredPlants = filterActivePlant(plantData);
      const pathName = window.location.pathname;
      if (pathName.length !== 1) {
        let locationCode = pathName.split("/")[1];
        filteredPlants.map((plant) => {
          if (plant.code == locationCode) {
            setLocalStorageItem("timeZone", plant.timeZone);
          }
        });
      }
      setLocationsData(filteredPlants);
    };

    const setPlantFeaturesConfig = (config) => {
      let plantCode = getLocalStorageItem("locationCode");
      let currentPlant = config.find((plant) => plant.code === plantCode);
      setPlantConfig(currentPlant.plantConfig);
      //Use the below Dummy Plant locations API for on-boarding
      // setPlantConfig(config);
    };

    //Use the below Dummy Plant locations API for on-boarding
    // const getPlantDetails = async (auth, locationCode) => {
    //   let plantData = await getApi(
    //     plantDetailsAPI + `${locationCode}.json`,
    //     apiHeadersFormatter(auth.accessToken)
    //   );
    //   if (plantData.status == 200 && plantData.data) {
    //     setPlantFeaturesConfig(plantData.data);
    //   } else {
    //     if (
    //       plantData.response.status == 401 ||
    //       plantData?.error == "Authentication Error!"
    //     ) {
    //       expireAuthToken();
    //     }
    //     getPlantDetails(auth);
    //   }
    // };
    // setTimeout(() => {
    //   let auth = getLocalStorageItem("auth");
    //   let locationCode = getLocalStorageItem("locationCode");
    //   if (auth && locationCode) {
    //     getPlantDetails(auth, locationCode);
    //   }
    // }, 2500);

    //Get All Plants Location Data Real-time data
    const getLocations = async (auth) => {
      let locData = await getApi(
        plantLocations,
        apiHeadersFormatter(auth.accessToken)
      );
      if (locData.status == 200 && locData.data) {
        //Filter Active Plants
        filterActivePlantsAndUpdateContext(locData);
        //Get current plant's config and set it to context
        setPlantFeaturesConfig(locData.data);
      } else {
        if (
          locData.response.status == 401 ||
          locData?.error == "Authentication Error!"
        ) {
          expireAuthToken();
        }
        getLocations(auth);
      }
    };
    setTimeout(() => {
      let auth = getLocalStorageItem("auth");
      let locationCode = getLocalStorageItem("locationCode");
      if (auth && locationCode) {
        getLocations(auth);
      }
    }, 2500);
  }, [getLocalStorageItem("locationCode")]);

  // get plant config from internal helper file
  // useEffect(() => {
  //   if (!locationCode) return;
  //   let plantConfig = getPlantConfig("all");
  //   setPlantConfig(plantConfig);
  // }, [locationCode]);

  const checkTimeMachineStatus = () => {
    if (
      !timeMachineModeIsEnabled ||
      !getLocalStorageItem("isTimeMachineModeEnabled")
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let auth = getLocalStorageItem("auth");
    let locationCodeLocal = JSON.parse(localStorage.getItem("locationCode"));
    if (auth !== null && locationCodeLocal) {
      if (checkTimeMachineStatus()) {
        const initiateShiftPerformanceWebSocket = () => {
          socketConnection.on(`${locationCodeLocal}_SHIFT_DETAILS`, (data) => {
            if (data.results && data.results !== undefined) {
              setShiftPerformanceData(data.results);
            }
          });
        };

        // Connected with Allocated View Socket Channel for Data
        const initiateAllocatedViewWebSocket = () => {
          socketConnection.on(
            `${locationCodeLocal}_DOCK_DOOR_ALLOCATED_VIEW`,
            (data) => {
              if (data.results && data.results !== undefined) {
                setAllocatedViewData(data.results);
              }
            }
          );
        };

        const initiateWarehouseWebSocket = () => {
          socketConnection.on(
            `${locationCodeLocal}_WAREHOUSE_CAPACITY`,
            (data) => {
              if (data.results && data.results !== undefined) {
                setWarehouseData(data.results);
              }
            }
          );
        };

        const initiateNonLgvInventoryWebSocket = () => {
          socketConnection.on(
            `${locationCodeLocal}_NON_LGV_DETAILS`,
            (data) => {
              if (
                data.results &&
                data.results !== "Error connecting to datasource!"
              ) {
                setNonLgvData(data.results);
              }
            }
          );
        };
        if (
          socketConnection &&
          !getLocalStorageItem("isTimeMachineModeEnabled")
        ) {
          initiateShiftPerformanceWebSocket();
          initiateWarehouseWebSocket();
          initiateNonLgvInventoryWebSocket();
          initiateAllocatedViewWebSocket();
        }
      }
    }
  }, [
    socketConnection,
    getLocalStorageItem("isTimeMachineModeEnabled"),
    getLocalStorageItem("auth"),
    getLocalStorageItem("locationCode"),
    timeMachineModeIsEnabled,
    locationCode,
  ]);

  useEffect(() => {
    if (localStorage.getItem("appSettings")) {
      let prevSwitchState = getLocalStorageItem("appSettings");
      if (prevSwitchState.removedLgv == true) {
        setRemovedLgvViewIsOpen(true);
      }
      prevSwitchState.destinationLines = false;
      setLocalStorageItem("appSettings", prevSwitchState);
    }
  }, []);

  const RenderFeatureListAsDraggables = useMemo(() => {
    if (socketConnection !== null) {
    }
    let featureList = [
      {
        key: 1,
        component: <Status socket={socketConnection} title="Shipment Status" />,
      },
      {
        key: 2,
        component: (
          <IndoorMapV2
            socket={socketConnection}
            lineProductionData={lineProductionData}
            dockDoorData={dockDoorData}
          />
        ),
      },
      {
        key: 3,
        component: (
          <ActiveShipment
            socket={socketConnection}
            title="Active Shipment Metrics"
          />
        ),
      },
      {
        key: 4,
        component: removedLgvViewIsOpen ? (
          <RemovedStatus
            socket={socketConnection}
            title="Removed LGV Status Time"
            scrollable={true}
          />
        ) : null,
      },
    ];
    let order = getLocalStorageItem("layout-order");
    if (order) {
      featureList.sort((a, b) => {
        return order.indexOf(a.key) - order.indexOf(b.key);
      });
    }

    if (featureList) {
      return (
        <DraggableComponent
          id="dashboard-table-id"
          draggableItems={featureList}
        />
      );
    }
  }, [
    reRenderKey,
    shipmentData,
    activeShipmentData,
    fleetStatus,
    fleetCount,
    fleetData,
    socketConnection,
    lineProductionData,
    dockDoorData,
    removedLgvViewIsOpen,
  ]);

  const renderBasedOnRoute = () => {
    switch (currentSubPath) {
      case "yard-view": {
        if (plantConfig?.yard?.isEnabled) {
          setActiveLGVModule("yard-view");
          return (
            <YardView socket={socketConnection} plantConfig={plantConfig} />
          );
        }
        return RenderFeatureListAsDraggables;
      }
      case "allocated-view": {
        setActiveLGVModule("allocated-view");
        return <AllocatedView data={allocatedViewData} />;
      }
      default: {
        return RenderFeatureListAsDraggables;
      }
    }
  };

  return (
    <div className="dashboard-container">
      {location && localStorage.getItem("auth") ? (
        <>
          <Navbar siteLocations={locationsData} />
          <div className="flexible-screen">
            <div className="flexible-left-screen">
              <Header />
              {timeMachineModeIsEnabled ? (
                <TimeMachine socket={socketConnection} />
              ) : (
                <>
                  {/* Shipment Status */}
                  {/* <Status title="Shipment Status" statusData={shipmentData} /> */}
                  {/* IndoorMap */}
                  {/* {socketConnection !== null ? (
                    <IndoorMapV2
                      socket={socketConnection}
                      lineProductionData={lineProductionData}
                      dockDoorData={dockDoorData}
                    />
                  ) : null} */}

                  {/* Active Shipment */}
                  {/* <ActiveShipment
                    title="Active Shipment Metrics"
                    statusData={activeShipmentData}
                  /> */}
                  {/* Removed Lgv Status */}
                  {/* {removedLgvViewIsOpen ? (
                    <RemovedStatus
                      title="Removed LGV Status Time"
                      fleetStatus={fleetStatus}
                      fleetCount={fleetCount}
                      removedStatusData={fleetData}
                      scrollable={true}
                    />
                  ) : null} */}
                  {/* {currentSubPath === "allocated-view" ? (
                    <AllocatedView data={allocatedViewData} />
                  ) : (
                    RenderFeatureListAsDraggables
                  )}

                  {isYardViewEnabled() && currentSubPath === "yard-view"
                    ? (setActiveLGVModule("yard-view"),
                      (
                        <YardView
                          socket={socketConnection}
                          plantConfig={plantConfig}
                        />
                      ))
                    : RenderFeatureListAsDraggables} */}

                  {renderBasedOnRoute()}
                  {layoutPopup ? <LayoutPopup /> : null}
                  {/* Customize Popup */}
                  {customizePopup ? <CustomizePopup /> : null}
                  {/* Warehouse Modal */}
                  {warehouseModelIsOpen ? (
                    <WarehouseModal
                      warehouseData={warehouseData}
                      shiftData={shiftPerformanceData}
                    />
                  ) : null}
                  {/* Additional Popup */}
                  {additionalDashboardModalIsOpen ? (
                    <AdditionalDashboardModal />
                  ) : null}
                  {allocatedView ? (
                    <AllocatedView data={allocatedViewData} />
                  ) : null}
                  {/* Legends Popup */}
                  {isLegendsPopupOpen ? <LegendsPopup /> : null}

                  {/* Lgv Popup */}
                  {selectedMarkerData &&
                  selectedMarkerType == "lgv" &&
                  isMarkerPopupOpen ? (
                    <LgvPopup lgv={selectedMarkerData} />
                  ) : null}

                  {/* Block Rack Modal */}
                  {isBlockRackModalOpen &&
                  Object.values(selectedBlockRackLocationData).length !== 0 ? (
                    <BlockRackModal location={selectedBlockRackLocationData} />
                  ) : null}

                  {/* Non LGV Modal */}
                  {socketConnection && isNonLgvModalOpen && nonLgvData ? (
                    <NonLgvModal nonLgvData={nonLgvData} />
                  ) : null}

                  {/* Dock Door Popup */}
                  {socketConnection &&
                  selectedMarkerData &&
                  selectedMarkerType == "dock" &&
                  isMarkerPopupOpen ? (
                    <DockDoorPopup
                      dockData={selectedMarkerData}
                      dockMode={JSON.parse(localStorage.getItem("dockMode"))}
                      socket={socketConnection}
                    />
                  ) : null}

                  {/* Line Production Popup */}

                  {selectedMarkerData &&
                  selectedMarkerType == "batteryStationObj" &&
                  isMarkerPopupOpen ? (
                    <BatteryStationObjectPopup
                      stationData={selectedMarkerData}
                    />
                  ) : null}

                  {selectedMarkerData &&
                  selectedMarkerType == "productionLine" &&
                  isMarkerPopupOpen ? (
                    <LineProductionShowModal lineData={selectedMarkerData} />
                  ) : null}
                  {isNoHeatMapDataModalOpen ? <TimeMachineNoDataModal /> : null}
                </>
              )}
            </div>
            <div className="flexible-right-screen">
              {(getScreenSize() > 2500 || isScreenBigger()) &&
              activeLGVModule == "dashboard" ? (
                <WarehouseModal
                  warehouseData={warehouseData}
                  shiftData={shiftPerformanceData}
                />
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <div>
          <Greetings openLocationModal={openLocationModel} />
        </div>
      )}
      {isHeatmapModeEnabled ? <Heatmap closeModal={closeHeatmapModal} /> : null}
      {locationModal ? (
        <MapModal
          selectedLocation={location}
          siteLocations={locationsData}
          closeModal={closeLocationModal}
        />
      ) : null}
      {alertObject.type.length !== 0 ? (
        <AlertMessage
          heading={alertObject.payload.heading}
          description={alertObject.payload.description}
          type={alertObject.type}
        />
      ) : null}
    </div>
  );
};

export default Dashboard;
